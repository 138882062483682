import React, { FC, useState } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { ContentItem } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import Link from "components/Link";
import Image from "components/Image";
var he = require('he');

export interface CardContentListProps {
  className?: string;
  post: ContentItem;
  ratio?: string;
  hiddenAuthor?: boolean;
}

const CardContentList: FC<CardContentListProps> = ({
  className = "h-full",
  post,
  hiddenAuthor = false,
  ratio = "aspect-w-4 aspect-h-3",
}) => {
  //const { title, href, categories, date } = post;

  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      //
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
      >
        <div>
          {/*<PostFeaturedMedia post={post} isHover={isHover} />*/}

          <Image
            alt={post.imgMainAlt}
            fill
            className="object-cover"
            src={post.PreviewImage?.toString()}
            sizes="(max-width: 600px) 480px, 800px"
          />
          <Link
            href={post.slug}
            className={`block absolute inset-0 bg-black/20 transition-opacity opacity-0 group-hover:opacity-100`}
          />
        </div>
      </div>
      <Link href={post.slug} className="absolute inset-0"></Link>
      {/*
      <span className="absolute top-3 inset-x-3 z-10">
        <CategoryBadgeList categories={categories} />
      </span>
      */}
      <div className="p-4 flex flex-col space-y-3">
        {/*
        {!hiddenAuthor ? (
          <PostCardMeta meta={post} />
        ) : (
          <span className="text-xs text-neutral-500">{date}</span>
        )}
        */}
        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={post.ContentTitle}>
            {post.ContentTitle}
          </span>
        </h3>
        <div>
          {he.decode(post.PreviewDesc?.toString())}
        </div>
        {/*
        <div className="flex items-end justify-between mt-auto">
          <PostCardLikeAndComment className="relative" />
          <PostCardSaveAction className="relative" />
        </div>
        */}
      </div>
    </div>
  );
};

export default CardContentList;
