import React, { FC, ReactNode } from "react";
import { ContentSidebar } from "../ContentSidebar";
import ContentPage from "../ContentPage";
import SingleRelatedPosts from "../SingleRelatedPosts";
import { ContentItem } from "data/types";

export interface LayoutContentProps { children: ReactNode, contentItem: ContentItem; }

const LayoutContent: FC<LayoutContentProps> = ({ children, contentItem}) => {
  return (
    <div className={`relative `}>
      {children}
      <div className="container flex flex-col my-10 lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
          <ContentPage contentItem={contentItem} />
        </div>
        <div className="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
          <ContentSidebar />
        </div>
      </div>

      {/* RELATED POSTS */}
      {/* 
      <SingleRelatedPosts />
      */}
    </div>
  );
};

export default LayoutContent;
