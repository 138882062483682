import ContentHeader from "app/(singles)/ContentHeader";
import LayoutContent from "../layout-content";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentItem } from "data/types";
import data from 'data/jsons/__carponents_content_legacy.json'; // Assuming the JSON file is imported directly


const PageContent = () => {
  const { slug } = useParams<{slug: string}>();
  // Adjust the useState to explicitly allow null or Item
  const [item, setItem] = useState<ContentItem | null>(null);

  useEffect(() => {
    // Lookup the item by slug. No need to change this part, but TypeScript should now be happy
    const foundItem = data.find((item: ContentItem) => item.slug === slug);
    // If foundItem is undefined, setItem will still receive null thanks to the || operator
    setItem(foundItem || null);
  }, [slug]);

  if (!item) {
    return <div>Sorry page does not exist!</div>;
  }

  return (
    <LayoutContent contentItem={item}>
      <div className="absolute top-0 inset-x-0 bg-neutral-900 dark:bg-black/30 h-[480px] md:h-[600px] lg:h-[700px] xl:h-[95vh]"></div>

      <header className="container pt-10 lg:pt-16 rounded-xl relative z-10">
        <ContentHeader contentItem={item}  />
      </header>
    </LayoutContent>
  );
}

export default PageContent;
